import React from "react"

const ComingSoon = props => (
  <svg viewBox="0 0 35 36" {...props}>
    <path
      d="M188.148148,0 L200,5.52734375 L200,32 L188.148148,32 C188.148148,21.3333333 188.148148,16 188.148148,16 C188.148148,16 188.148148,10.6666667 188.148148,0 Z"
      id="Rectangle-14-Copy"
      fill="#A27127"
    ></path>
    <path
      d="M-3.55271368e-13,-3.55271368e-15 L200,-3.55271368e-15 L200,32 L-3.55271368e-13,32 C8.78569835,21.3333333 13.1785475,16 13.1785475,16 C13.1785475,16 8.78569835,10.6666667 -3.55271368e-13,-3.55271368e-15 Z"
      id="Rectangle-14"
    ></path>
    <text
      id="Register-Now"
      fontFamily="Bitter, Garamond, Baskerville, Times New Roman, serif;"
      fontSize="12"
      fontWeight="bold"
      fill="#FFFFFF"
    >
      <tspan x="25" y="20">
        Coming Soon!
      </tspan>
    </text>
  </svg>
)

export default ComingSoon
