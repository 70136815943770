import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import * as Icons from "./icons"
import "./styles.scss"

const Icon = ({ name, inline, marginLeft, className, marginRight }) => {
  const IconComponent = Icons[name]

  const getClassnames = () =>
    classNames(
      "icon",
      `icon--${name}`,
      inline && "icon--inline",
      marginLeft && "icon--margin-left",
      marginRight && "icon--margin-right",
      className
    )

  return <IconComponent className={getClassnames()} />
}

Icon.defaultProps = {
  inline: false,
  marginLeft: false,
  marginRight: false,
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  /** Adds a small margin to the left of the icon */
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
}

export default Icon
