import React from "react"

const Module = props => (
  /* Module SVG Code will go here (the icon with the index finger pointing/clicking on a button || Need SVG from creative) */
  <svg viewBox="0 0 28 28" {...props}>
    <path d="M11.874 6.551c.85 0 1.54.688 1.544 1.538v6.148l4.557 1.538c1.013.326 1.235 1.54.998 2.578l-1.052 5.641h-8.18l-4.116-6.163c-.465-.714-.465-2.06.557-2.06s2.113.422 4.148 3.58V8.062l.015.01a1.526 1.526 0 011.53-1.52zm6.687-6.26a4.027 4.027 0 014.021 4.029v5.727a4.027 4.027 0 01-4.02 4.021h-.522a.896.896 0 110-1.792h.521a2.231 2.231 0 002.229-2.229V4.32a2.231 2.231 0 00-2.229-2.228H5.021A2.231 2.231 0 002.793 4.32v5.727a2.231 2.231 0 002.228 2.229h.521a.896.896 0 110 1.792h-.521A4.027 4.027 0 011 10.048V4.32A4.027 4.027 0 015.02.29zm.335 3.659a.542.542 0 01.572.506v5.215a.542.542 0 01-.572.507h-4.412c-.008-.45-.02-.782-.038-1.014h3.875V4.963H5.284v4.201h3.912c-.012.235-.039.57-.039 1.014H4.713a.542.542 0 01-.572-.507V4.456a.542.542 0 01.572-.506zM6.646 5.622c.329 0 .606.247.644.574v1.739a.649.649 0 01-1.289 0v-1.74a.649.649 0 01.645-.573z" />
  </svg>
)

export default Module
