import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./styles.scss"

const Hamburger = ({ isActive, onClick }) => (
  <button
    type="button"
    className={classNames("hamburger", isActive && "hamburger--is-active")}
    onClick={onClick}
  >
    <div className="hamburger__inner"></div>
  </button>
)

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Hamburger
