import React from "react"
import PropTypes from "prop-types"
import Header from "../Header"
import Footer from "../Footer"
import { AuthProvider } from "../../context/AuthContext"

const Layout = ({ children }) => (
  <AuthProvider>
    <Header />
    <main>{children}</main>
    <Footer />
  </AuthProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
