import React from "react"

const ExternalLink = props => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="M12.288 8.2c.512 0 .896.4.96.933v5.934c0 .533-.384.933-.896.933H.96c-.512 0-.896-.4-.896-.933V3.2c0-.533.384-.933.832-.933h5.696c.512 0 .896.4.896.933s-.384.933-.896.933h-4.8v10h9.6v-5c0-.533.384-.933.896-.933zM15.296.533c.064.134.064.267.064.4V6.4c0 .467-.32.933-.832.933s-.896-.4-.896-.933V3.133l-8.96 9.334a.805.805 0 01-.64.266.805.805 0 01-.64-.266c-.32-.334-.32-.934 0-1.267l8.96-9.333H9.216c-.512 0-.896-.4-.896-.934C8.32.4 8.704 0 9.216 0h5.248c.128 0 .256 0 .384.067a.79.79 0 01.448.466z"></path>
    <use xlinkHref="#externallink-icon" transform="translate(-.35)" />
  </svg>
)

export default ExternalLink
