import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Column } from "../Grid"
import Image from "../Image"
import "./styles.scss"

const Hero = ({ title, body, infographic, image }) => {
  return (
    <Container>
      <Row>
        <Column span={6}>
          <h1 className="hero__title">{title}</h1>
          <div className="hero__infographic">
            <Image filename={infographic} />
          </div>
          <p className="hero__subhead">{body}</p>
        </Column>
        <Column span={6}>
          <Image filename={image} className="hero__image" />
        </Column>
      </Row>
    </Container>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  infographic: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
}

export default Hero
