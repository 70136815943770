import Label from "./Label"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./styles.scss"

const Checkbox = ({ label, labelHidden, name, onChange, ...rest }) => {
  const getClassNames = () =>
    classNames("form__item", "form__item--checkbox", `form__item--${name}`)

  const handleChange = event => {
    onChange && onChange(event.target.name, event.target.checked)
  }

  return (
    <div className={getClassNames()}>
      <input
        type="checkbox"
        name={name}
        id={name}
        className="form__input"
        onChange={handleChange}
        {...rest}
      />
      <Label label={label} name={name} />
    </div>
  )
}

Checkbox.defaultProps = {
  labelHidden: false,
  onChange: null,
}

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
