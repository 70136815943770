import PropTypes from "prop-types"
import React from "react"
import Icon from "../Icon"

const OutboundLink = ({ children, to, withIcon, className }) => {
  return (
    <a
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {withIcon && <Icon name="externallink" inline />}
      {children}
    </a>
  )
}

OutboundLink.defaultProps = {
  withIcon: false,
}

OutboundLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
}

export default OutboundLink
