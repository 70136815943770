import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Icon from "../Icon"
import Modal from "../Modal"
import OutboundLink from "../OutboundLink"
import Video from "../Video"
import "./styles.scss"

const Action = ({
  label,
  type,
  format,
  size,
  path,
  className,
  videoClassName,
  url,
  youtube_id,
  vimeo_id,
  azure_src,
  guidSrc,
  posterSrc,
  autoplay,
  locked,
  assetId,
  ccLang,
  moduleName,
}) => {
  return (
    <div
      className={classNames(
        "resource__action",
        locked && "resource__action--locked"
      )}
    >
      {locked ? (
        <div className="resource__action-placeholder">
          <Icon name="lock" inline />
          {label}
        </div>
      ) : (
        <>
          {type === "external" && (
            <OutboundLink to={url} className={className} withIcon>
              {label}
            </OutboundLink>
          )}
          {type === "video" && (
            <Modal
              trigger={
                <button>
                  <Icon name="play" inline />
                  {label}
                </button>
              }
              video
            >
              <div className="video">
                <div className={classNames("video__player", videoClassName)}>
                  <Video
                    youtube_id={youtube_id}
                    vimeo_id={vimeo_id}
                    azure_src={azure_src}
                    guidSrc={guidSrc}
                    posterSrc={posterSrc}
                    autoplay={autoplay}
                    assetId={assetId}
                    ccLang={ccLang}
                    videoClassName={videoClassName}
                  />
                </div>
              </div>
            </Modal>
          )}
          {type === "download" && (
            <a
              href={path}
              target="_blank"
              className={className}
              rel="noopener noreferrer"
            >
              <Icon name="download" inline />
              {label}
            </a>
          )}
          {type === "module" && (
            <a
              href={path}
              target="_blank"
              className={className}
              data-click-name={moduleName}
              data-click-type="Module Launch"
              rel="noopener noreferrer"
            >
              <Icon name="module" inline />
              {label}
            </a>
          )}
        </>
      )}

      {format && <span className="resource__format">{format}</span>}
      {size && <span className="resource__size">{size}</span>}
    </div>
  )
}

Action.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  format: PropTypes.string,
  size: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  youtube_id: PropTypes.string,
  vimeo_id: PropTypes.string,
  azure_src: PropTypes.string,
  guidSrc: PropTypes.string,
  posterSrc: PropTypes.string,
  autoplay: PropTypes.bool,
  locked: PropTypes.bool,
  moduleName: PropTypes.string,
}

export default Action
