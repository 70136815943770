const gaId = "UA-150717694-1"

module.exports = {
  siteMetadata: {
    siteUrl: `https://doseofknowledge.com/`,
    gaId: gaId,
    partner: `CVS Health Foundation`,
    title: `Dose of Knowledge`,
    description: `Dose of Knowledge: A Pharmacists Teach program. The no-cost digital education prevention program designed to empower students to make smart, safe, and healthy choices. `,
    oauthClientId: "0007bf86-f9d9-1e56-8a47-f8a50a5c0000",
    oauthAuthEndpoint:
      "https://federationw.cvshealth.com/affwebservices/CASSO/oidc/authorize",
    author: "@discoveryed",
    twitterText:
      "Empower students to make good decisions around substance misuse for the health and wellbeing of themselves and their community with %23DoseofKnowledge by @DiscoveryEd and @CVSHealth www.DoseofKnowledge.com",
    emailSubject: "Dose of Knowledge, A Pharmacists Teach Program",
    emailBody:
      "CVS Health and Discovery Education have partnered to expand the Pharmacists Teach program into the classroom with a no-cost prevention program, Dose of Knowledge. The program strives to empower educators and pharmacists to address substance misuse and educate students to make good decisions for the health and wellbeing of themselves and their community. Visit www.DoseofKnowledge.com.",
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/theme/favicon.png`,
      },
    },
    `gatsby-plugin-sass`,
    {
      resolve: "@discoveryed/de-analytics",
      options: {
        partnershipId: "226f8b9f-3248-4758-ab53-b247175993af",

        // Removed Segment keys to use Legacy key
        // stageTrackingId: 'F7GWwYsqIAzJxsU65zfgIKDmFYcIQmvD',
        // devTrackingId:  'a9U0PWohUAV2xuKP3FAi09iNt55vEaCM',
        // prodTrackingId: '5seaYkvc7SCITVV9H8tJUYQ77OIYjTzS'
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
