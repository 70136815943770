import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import Icon from "../Icon"
import Image from "../Image"
import Modal from "../Modal"
import "./styles.scss"

const Video = ({
  vimeo_id,
  youtube_id,
  azure_src,
  guidSrc,
  posterSrc,
  autoplay,
  imgPlaceholder,
  assetId,
  className,
  videoClassName,
  ccLang,
}) => {
  const [hostname, setHostname] = useState("")
  const [loaded, setLoaded] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          gaId
        }
      }
    }
  `)

  useEffect(() => {
    setLoaded(true)
    setHostname(
      window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
    )
  }, [])

  const getUrl = () => {
    if (vimeo_id) {
      return `https://vimeo.com/${vimeo_id}`
    }

    if (youtube_id) {
      return `https://youtu.be/${youtube_id}`
    }

    return null
  }

  const renderVideo = () => {
    if (azure_src) {
      return (
        <iframe
          src={`https://embed.discoveryeducation.com?videoSrc=${encodeURI(
            azure_src +
              "&gaId=" +
              data.site.siteMetadata.gaId +
              (posterSrc ? "&posterSrc=" + hostname + posterSrc : "") +
              (autoplay ? "&autoPlay=true" : "") +
              (assetId ? `&assetId=${assetId}` : "") +
              (ccLang ? `&ccLang=${ccLang}` : "")
          )}`}
          title="Video"
          frameBorder="0"
          allowFullScreen
        />
      )
    } else if (guidSrc) {
      return (
        <div className={classNames("video_new", className)}>
          <iframe
            src={`https://app.discoveryeducation.com/learn/embedded-player/${guidSrc}?utm_source=microsite&utm_medium=embed`}
            width="560"
            height="349"
            frameBorder="0"
            scrolling="no"
            autoPlay={autoplay}
            allowFullScreen
          ></iframe>
        </div>
      )
    } else {
      return (
        <ReactPlayer
          url={getUrl()}
          width="100%"
          // height="100%"
          controls
          playing
        />
      )
    }
  }

  if (imgPlaceholder) {
    return (
      <div className="video">
        <Modal
          trigger={
            <button>
              <Icon name="play" inline />
              <Image filename={imgPlaceholder} />
            </button>
          }
          video
        >
          <div className="video">
            <div className={classNames("video__player", videoClassName)}>
              {loaded && renderVideo()}
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  return renderVideo()
}

Video.defaultProps = {
  vimeo_id: null,
  azure_src: null,
  youtube_id: null,
  imgPlaceholder: null,
  assetId: null,
  ccLang: null,
  autoplay: false,
  guidSrc: null,
}

Video.propTypes = {
  vimeo_id: PropTypes.string,
  youtube_id: PropTypes.string,
  azure_src: PropTypes.string,
  autoplay: PropTypes.bool,
  imgPlaceholder: PropTypes.string,
  assetId: PropTypes.string,
  ccLang: PropTypes.string,
  guidSrc: PropTypes.string,
}

export default Video
