import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

const Button = ({ children, type, ...rest }) => (
  <div className="form__item form__item--button">
    <button type={type} className="form__button" {...rest}>
      {children}
    </button>
  </div>
)

Button.defaultProps = {
  type: "submit",
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}

export default Button
