import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./styles.scss"

const Label = ({ label, labelHidden, name, required }) => {
  const getClassNames = () =>
    classNames(
      "form__label",
      labelHidden && "form__label--hidden",
      required && "form__label--required"
    )

  return (
    <label htmlFor={name} className={getClassNames()}>
      {label}
    </label>
  )
}

Label.defaultProps = {
  labelHidden: false,
  required: false,
}

Label.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  required: PropTypes.bool,
}

export default Label
