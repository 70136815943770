import PropTypes from "prop-types"
import classNames from "classnames"
import React from "react"
import "./styles.scss"

const Column = ({ span, children, className }) => {
  const getClassNames = () => classNames("column", `is-${span}`, className)

  return <div className={getClassNames()}>{children}</div>
}

Column.defaultProps = {
  span: 12,
  className: null,
}

Column.propTypes = {
  span: PropTypes.number,
  className: PropTypes.string,
}

export default Column
