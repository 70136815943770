import PropTypes from "prop-types"
import classNames from "classnames"
import React from "react"
import "./styles.scss"

const Row = ({ children, className }) => {
  const getClassNames = () => classNames("row", className)

  return <div className={getClassNames()}>{children}</div>
}

Row.defaultProps = {
  className: null,
}

Row.propTypes = {
  className: PropTypes.string,
}

export default Row
