import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Select from "./Select"
import TextInput from "./TextInput"

const states = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

class SchoolLookup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      schools: [],
      manualEntry: false,
      fields: {
        inst: "",
        mstreet: "",
        mcity: "",
        mstate: "",
        mzipcode: "",
        district: "",
      },
    }
  }

  componentDidMount() {
    this.getSchools()
  }

  getSchools = () => {
    const { zip } = this.props

    // start spinner

    axios
      .get(`https://cepdata.discoveryeducation.com/api/v2/schools`, {
        params: {
          zip,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(schools => {
        this.setState({
          schools: schools.data.data,
        })
      })
      .catch(error => {
        // handle errors
      })
      .finally(() => {
        //end spinner
      })
  }

  getSchoolDetails = schoolName => {
    const { schools } = this.state
    for (let school of schools) {
      if (school.inst === schoolName) {
        return school
      }
    }
  }

  handleSchoolChange = (name, value) => {
    const { onChange } = this.props
    const school = this.getSchoolDetails(value)
    this.setState(
      {
        fields: school,
      },
      () => {
        onChange && onChange("school", this.state.fields)
      }
    )
  }

  handleManualInputChange = (name, value) => {
    const { onChange } = this.props
    this.setState(
      state => ({
        fields: {
          ...state.fields,
          [name]: value,
        },
      }),
      () => {
        const { fields } = this.state
        const isValid = Object.keys(fields).every(key => fields[key] !== "")
        if (isValid && onChange) {
          onChange("school", fields)
        }
      }
    )
  }

  handleClick = () => {
    this.setState({
      manualEntry: true,
    })
  }

  renderSelect = () => {
    const { schools, fields } = this.state
    return (
      <>
        <Select
          name="school"
          label="Select Your School"
          placeholder="Select Your School"
          options={schools.map(school => school.inst)}
          value={fields.inst}
          labelHidden
          onChange={this.handleSchoolChange}
        />
        <p>
          Not seeing your school? Home schooled?
          <br />
          <button
            className="form__school-lookup-button"
            type="button"
            onClick={this.handleClick}
          >
            Enter your information ›
          </button>
        </p>
      </>
    )
  }

  renderEntryForm = () => {
    const { fields } = this.state
    return (
      <>
        <TextInput
          type="text"
          label="School/Homeschool Name"
          name="inst"
          placeholder="School/Homeschool Name"
          value={fields.inst}
          labelHidden
          onChange={this.handleManualInputChange}
        />
        <TextInput
          type="text"
          label="School/Homeschool Address"
          name="mstreet"
          placeholder="School/Homeschool Address"
          value={fields.mstreet}
          labelHidden
          onChange={this.handleManualInputChange}
        />
        <TextInput
          type="text"
          label="School/Homeschool City"
          name="mcity"
          placeholder="School/Homeschool City"
          value={fields.mcity}
          labelHidden
          onChange={this.handleManualInputChange}
        />
        <Select
          name="mstate"
          label="School/Homeschool State"
          placeholder="School/Homeschool State"
          options={states}
          value={fields.mstate}
          labelHidden
          onChange={this.handleManualInputChange}
        />
        <TextInput
          type="text"
          label="School/Homeschool Postal Code"
          name="mzipcode"
          placeholder="School/Homeschool Postal Code"
          value={fields.mzipcode}
          labelHidden
          onChange={this.handleManualInputChange}
        />
        <TextInput
          type="text"
          label="School/Homeschool District"
          name="district"
          placeholder="School/Homeschool District"
          value={fields.district}
          labelHidden
          onChange={this.handleManualInputChange}
        />
      </>
    )
  }

  render() {
    return this.state.manualEntry ? this.renderEntryForm() : this.renderSelect()
  }
}

SchoolLookup.propTypes = {
  zip: PropTypes.number.isRequired,
}

export default SchoolLookup
